<template>
  <div class="humanity-container">
    <div
      v-for="(item, index) in humanisticList"
      :key="index"
      class="humanity-list"
    >
      <div class="humanity-item" @click="openPage(item.id, item.outUrl)">
        <van-image
          width="100%"
          height="100%"
          radius="10"
          :src="item.rwImage"
          fit="cover"
        />
        <div class="item-title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHumanisticList } from "@/service";
export default {
  components: {},
  data() {
    return {
      humanisticList: [],
    };
  },
  mounted() {
    this.getHumanisticList();
  },
  methods: {
    openPage(id, webview) {
      if (webview.includes("http")) {
        sessionStorage.setItem("webview", webview);
        window.location.href = webview;
        // this.$nextTick(() => {
        //     this.$router.push(`/webview?id=${id}`);
        // });
        return false;
      }
      this.$router.push(`/humanity/details?id=${id}`);
    },
    async getHumanisticList() {
      const params = {
        pageNo: 1,
        pageSize: 1000,
      };
      const { data } = await getHumanisticList(params);
      this.humanisticList = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
.humanity-container {
  padding: 40px 36px;
  .humanity-list {
    margin-top: 30px;
  }
  .humanity-item {
    width: 100%;
    height: 372px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
    position: relative;
    .item-title {
      position: absolute;
      left: 30px;
      bottom: 20px;
      font-size: 24px;
      color: #fff;
      z-index: 1000;
    }
  }
}
</style>